import { Article } from '@/Article';
import CLink from '@/CLink';
import { CommonHero } from '@/CommonHero';
import { PrimaryBtn } from '@/buttons';
import { getNavLink, info } from '@/layout/NavLinks';
import { smTxt } from '@/layout/styles/Typography';
import { flexSpace } from '@/layout/styles/classes';
import appointmentIcon from '@a/icons/appointment.svg';
import styled from '@emotion/styled';
import { StaticImage } from 'gatsby-plugin-image';
import { Fragment } from 'react';

const TheArticle = styled(Article)`
    > h5 {
        margin: 32px 0 16px;

        :first-of-type {
            margin-top: 0;
        }
    }

    > h6 {
        margin: 1em 0;
    }

    > p {
        margin: 0;
    }

    > a {
        :first-of-type {
            text-decoration: underline;
            transition: color 0.3s ease-in-out;

            :hover,
            :focus {
                color: ${({ theme }) => theme.colors.green2};
            }
        }

        :last-of-type {
            display: flex;
            margin: 32px auto 0;
            max-width: max-content;
            padding: 24px 75px;

            > img {
                margin-right: 16px;
            }
        }
    }

    @media (min-width: 1024px) {
        > h5 {
            margin: 64px 0 32px;

            :first-of-type {
                margin-top: 0;
            }
        }
    }
`;

const OfficeHours = styled.div`
    max-width: 462px;

    > p {
        ${flexSpace};
        align-items: flex-start;
        margin: 0;

        > span:last-of-type {
            text-align: right;
            em {
                ${smTxt};
                font-style: normal;
            }
        }
    }
`;

const Hours = styled.div`
    max-width: 300px;

    > p {
        ${flexSpace};
        align-items: flex-start;
        margin: 0;
    }
`;

export default function DanaPointPage() {
    return (
        <Fragment>
            <CommonHero>
                <StaticImage
                    src="../../assets/images/dana-hero.jpg"
                    alt="yeliana nikolskaya and dawn marie"
                    width={810}
                    height={485}
                    loading="eager"
                />
            </CommonHero>
            <TheArticle>
                <h5>Operated By</h5>
                <p style={{ marginBottom: '32px' }}>Yeliana Nikolskaya & Dawn Marie</p>

                <h5>Office Hours</h5>

                <OfficeHours>
                    <p>
                        <span>Wednesday: </span>
                        <span>
                            2:00 PM - 7:00 PM <em>(by appointment only)</em>
                        </span>
                    </p>
                    <p>
                        <span>Thursday: </span>
                        <span>
                            12:00 PM - 7:00 PM <em>(by appointment only)</em>
                        </span>
                    </p>
                </OfficeHours>

                <h6>Aesthetician Schedule</h6>
                <Hours>
                    <p>
                        <span>Sunday:</span> <span>10:00 AM - 4:00 PM</span>
                    </p>
                    <p>
                        <span>Monday:</span> <span>8:00 AM - 9:00 PM</span>
                    </p>
                    <p>
                        <span>Tuesday:</span> <span>9:00 AM - 7:00 PM</span>
                    </p>
                    <p>
                        <span>Wednesday:</span> <span>8:00 AM - 9:00 PM</span>
                    </p>
                    <p>
                        <span>Thursday:</span> <span>9:00 AM - 1:00 PM</span>
                    </p>
                    <p>
                        <span>Friday:</span> <span>8:00 AM - 9:00 PM</span>
                    </p>
                    <p>
                        <span>Saturday:</span> <span>8:00 AM - 9:00 PM</span>
                    </p>
                </Hours>

                <h5>Location</h5>

                <CLink to={info.secondAddress.text}>{info.secondAddress.text}</CLink>

                <PrimaryBtn big="true" as={CLink} to={getNavLink('appointment')}>
                    <img src={appointmentIcon} alt="appointment" />
                    Book Appointment
                </PrimaryBtn>
            </TheArticle>
        </Fragment>
    );
}
